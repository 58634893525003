import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// import { Provider } from 'react-redux';
import { AuthProvider } from 'react-auth-kit'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js'
import './index.css';
import App from './App';
// import store from './redux/store';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider
    authType={"cookie"}
    authName={"shopify_admin_cookie"}
    cookieDomain={window.location.hostname}
    cookieSecure={true}
  >
    {/* <Provider store={store}> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    {/* </Provider> */}
  </AuthProvider>
);
